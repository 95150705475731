import { useState, createContext, ReactNode, useContext, useEffect } from 'react';
import { useLocation, Outlet, useNavigate } from 'react-router-dom';
import { Popover } from '@headlessui/react';
import Pusher from 'pusher-js';
import PrivateChannel from 'pusher-js/types/src/core/channels/private_channel';

// Utils
import NetworkManager from '../NetworkManager';

// Components 
import { TopMenu } from '../User/TopMenu';

// Interfaces
import { IUser, IRestaurant, IAuthContext } from './Interfaces';

const AuthContext = createContext<IAuthContext>(null!);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<IUser>(null!);
  const [restaurant, setRestaurant] = useState<IRestaurant>(null!);
  let currentUser: IUser = user;

  const updateToken = (JWT: string): void => {
    if (user?.JWT && JWT !== user?.JWT) {
      currentUser.JWT = JWT;
      setUser({ ...user, JWT });
      window.localStorage.setItem('JWT', JWT);
    }
  };

  const getToken = (): string => (
    currentUser?.JWT ||
    window.localStorage.getItem("JWT") ||
    ""
  );

  const updateUser = (user: IUser): void => {
    setUser(user);
    currentUser = user;

    window.localStorage.setItem('id', user._id);
    window.localStorage.setItem('user', user.username);
    window.localStorage.setItem('displayName', user.displayName);
    window.localStorage.setItem('profile', JSON.stringify(user.profiles));
    window.localStorage.setItem('JWT', user.JWT);
  };

  const logoutUser = (): void => {
    setUser(null!);
    setRestaurant(null!);

    window.localStorage.removeItem('id');
    window.localStorage.removeItem('user');
    window.localStorage.removeItem('displayName');
    window.localStorage.removeItem('profile');
    window.localStorage.removeItem('JWT');
  };

  const updateRestaurant = (restaurant: IRestaurant): void => {
    setRestaurant(restaurant);
  };

  const networkManager = new NetworkManager(getToken, logoutUser, updateToken);

  // TODO: reiew pusher authorized
  const pusher = new Pusher(window._env_.PUSHER_APPKEY, {
    authorizer: (channel) => networkManager.pusherAuthorizerStaff(channel),
    cluster: window._env_.PUSHER_CLUSTER
  });

  let restaurantChannel: PrivateChannel = null!;

  if(restaurant?.channelId) {
    const restaurantChannelId = restaurant?.channelId; 
    restaurantChannel = pusher.subscribe(restaurantChannelId) as PrivateChannel;
    restaurantChannel.authorize = (socketId, callback) => networkManager.pusherAuthorizerRestaurant(socketId, restaurantChannelId, user.JWT, callback);
  }

  const value = {
    user,
    restaurant,
    updateToken,
    getToken,
    updateUser,
    updateRestaurant,
    logoutUser,
    networkManager,
    pusher,
    restaurantChannel
  };

  useEffect(() => {
    return () => {
      restaurantChannel && restaurantChannel.unbind()
    }
  });

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);

export const RequireAuth = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const urlPrefix = location?.pathname?.split("/");

  useEffect(() => {
    const isSuperAdmin = auth.user?.profiles.find(profile => profile === "SUPERADMIN");

    if (!isSuperAdmin && urlPrefix[1] === "superadmin") {
      navigate("/home");
    }

    if (isSuperAdmin && urlPrefix[1] !== "superadmin") {
      navigate("/superadmin/home");
    }

  }, [urlPrefix]);

  useEffect(() => {
    auth.networkManager.getProfile().then(({ success, payload, message }) => {
      if (!success) {
        auth.logoutUser();
        navigate("/login", { state: { from: location } });
        return;
      }

      const user: IUser = {
        _id: payload?._id,
        username: payload?.username,
        displayName: payload?.displayName,
        profiles: payload?.profiles,
        JWT: auth.getToken()
      };


      if (user?._id && !auth.user) {
        auth.updateUser(user);
      }

      const restaurantInfo = payload?.restaurantInfo;

      if (restaurantInfo) {
        const restaurant: IRestaurant = {
          _id: restaurantInfo?._id,
          name: restaurantInfo?.name,
          owner: restaurantInfo?.owner,
          channelId: restaurantInfo?.channelId,
          surveyData: restaurantInfo?.surveyData,
          welcomeMessage: restaurantInfo?.welcomeMessage
        };

        auth.updateRestaurant(restaurant);

        if (!auth.restaurant) {
          auth.updateRestaurant(restaurant);
        }
      }

    });
  }, []);

  return (
    // HOTFIX FOR NEW VIEWS
    <Popover>
      { urlPrefix[1] !== "restaurant" &&
       <TopMenu />
      }

      <div className={`${urlPrefix[1] !== "restaurant" && "flex p-3 bg-gray-100 h-full"}`}>
        <Outlet />
      </div>
    </Popover>
  );
};