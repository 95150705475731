import * as PusherTypes from "pusher-js";

// Interfaces
import {
  BuzzItem,
  IHandshakeResponse,
  IMessage,
  IRestaurantInfo,
  ITable,
} from "./Interfaces/generalInterfaces";
import { ISurveyData } from "./Auth/Interfaces";
import { ETypeOfMenu } from "./User/Components/NewMenu";
import { BroadCasterConfig } from "./RestaurantViews/Components/NotificationSetting/Notification.interface";

export interface INetworkResponse<T> {
  success: boolean;
  payload?: T;
  message?: string;
}

type TGetToken = () => string;
type TLogoutUser = () => void;
type TUpdateToken = (JWT: string) => void;

const axios = require("axios").default;
const API_URL: string = window._env_.API_URL || "http://localhost:3006";

export default class NetworkManager {
  getToken: TGetToken;
  logoutUser: TLogoutUser;
  updateJWT: TUpdateToken;

  constructor(
    getToken: TGetToken,
    logoutUser: TLogoutUser,
    updateJWT: TUpdateToken
  ) {
    this.getToken = getToken;
    this.logoutUser = logoutUser;
    this.updateJWT = updateJWT;

    axios.interceptors.response.use(
      (response: any) => {
        return response;
      },
      (error: any) => {
        return Promise.reject(error);
      }
    );
  }
  static async translateText(
    message: string,
    targetLang: string
  ): Promise<any> {
    return await axios.post(`${API_URL}/translate`, {
      targetLang,
      message,
    });
  }
  static async detectLanguage(text: string): Promise<any> {
    return await axios.post(`${API_URL}/translate/detect`, {
      text,
    });
  }

  async toggleRestaurantLanguage(): Promise<INetworkResponse<any>> {
    const { data } = await axios.put(
      `${API_URL}/restaurants/language/toggle`,
      {},
      {
        headers: {
          Authorization: this.getAuthorization(),
        },
      }
    );
    return { success: true, payload: data };
  }
  async getRestaurantLanguage(): Promise<INetworkResponse<any>> {
    const { data } = await axios.get(`${API_URL}/restaurants/language/`, {
      headers: {
        Authorization: this.getAuthorization(),
      },
    });
    return { success: true, payload: data };
  }
  async updateRestaurantLanguage(
    restaurantLanguage: any
  ): Promise<INetworkResponse<any>> {
    try {
      const resp = await axios.put(
        `${API_URL}/restaurants/language/`,
        restaurantLanguage,
        {
          headers: {
            Authorization: this.getAuthorization(),
          },
        }
      );
      return { success: true, payload: null };
    } catch (error: any) {
      const errorMessage = error.message || "Unhandled error updating language";
      console.error(error);
      return { success: false, payload: null, message: errorMessage };
    }
  }

  async updateBroadCastingService(
    broadcasterService: Array<BroadCasterConfig>
  ): Promise<INetworkResponse<any>> {
    try {
      const resp = await axios.put(
        `${API_URL}/restaurants/broadcaster`,
        broadcasterService,
        {
          headers: {
            Authorization: this.getAuthorization(),
          },
        }
      );
      return { success: true, payload: null };
    } catch (error: any) {
      const errorMessage = error.message || "Unhandled error updating language";
      console.error(error);
      return { success: false, payload: null, message: errorMessage };
    }
  }

  async login(username: string, password: string): Promise<any> {
    try {
      const { data } = await axios.post(`${API_URL}/auth/login`, {
        username,
        password,
      });

      if (!data?.access_token) {
        const errorMessage = data.message || "Unhandled error at login";
        return { success: false, payload: null, message: errorMessage };
      }

      return { success: true, payload: data.access_token };
    } catch (error: any) {
      const errorMessage = error.message || "Unhandled error at login";
      console.error(error);
      return { success: false, payload: null, message: errorMessage };
    }
  }

  getAuthorization(): string {
    return "Bearer " + this.getToken();
  }

  async getProfile() {
    try {
      const { data } = await axios.get(`${API_URL}/profile`, {
        headers: {
          Authorization: this.getAuthorization(),
        },
      });

      if (!data) {
        const errorMessage = data.message || "Unhandled error getting profile";
        return { success: false, payload: null, message: errorMessage };
      }

      return {
        success: true,
        payload: data,
      };
    } catch (error: any) {
      const errorMessage = error.message || "Unhandled error at get profile";
      console.error(error);
      return { success: false, payload: null, message: errorMessage };
    }
  }

  async getUsers(showAll: boolean = false): Promise<INetworkResponse<any>> {
    try {
      const url = showAll
        ? `${API_URL}/users?showDeleted=true`
        : `${API_URL}/users`;
      const { data } = await axios.get(url, {
        headers: {
          Authorization: this.getAuthorization(),
        },
      });

      return { success: true, payload: data };
    } catch (error: any) {
      const errorMessage = error.message || "Unhandled error getting users";
      console.error(error);
      return { success: false, payload: null, message: errorMessage };
    }
  }

  async addUser(
    username: string,
    displayName: string,
    profiles: string[],
    password: string
  ): Promise<INetworkResponse<any>> {
    try {
      const { data } = await axios.post(
        `${API_URL}/users`,
        {
          restaurantId: "",
          username,
          password,
          displayName,
          profiles,
        },
        {
          headers: {
            Authorization: this.getAuthorization(),
          },
        }
      );

      return { success: true, payload: data.insertedId };
    } catch (error: any) {
      const errorMessage = error.message || "Unhandled error adding new user";
      console.error(error);
      return { success: false, payload: null, message: errorMessage };
    }
  }

  async deleteUser(id: string): Promise<INetworkResponse<any>> {
    try {
      const { data } = await axios.delete(`${API_URL}/users/${id}`, {
        headers: {
          Authorization: this.getAuthorization(),
        },
      });

      return { success: true, payload: null };
    } catch (error: any) {
      const errorMessage = error.message || "Unhandled error deleting user";
      console.error(error);
      return { success: false, payload: null, message: errorMessage };
    }
  }

  async softDeleteUser(id: string): Promise<INetworkResponse<any>> {
    try {
      const { data } = await axios.post(
        `${API_URL}/users/${id}/softdelete`,
        {},
        {
          headers: {
            Authorization: this.getAuthorization(),
          },
        }
      );

      return { success: true, payload: null };
    } catch (error: any) {
      const errorMessage =
        error.message || "Unhandled error soft-deleting user";
      console.error(error);
      return { success: false, payload: null, message: errorMessage };
    }
  }

  async undoSoftDeleteUser(id: string): Promise<INetworkResponse<any>> {
    try {
      const { data } = await axios.post(
        `${API_URL}/users/${id}/undosoftdelete`,
        {},
        {
          headers: {
            Authorization: this.getAuthorization(),
          },
        }
      );

      return { success: true, payload: null };
    } catch (error: any) {
      const errorMessage = error.message || "Unhandled error restoring user";
      console.error(error);
      return { success: false, payload: null, message: errorMessage };
    }
  }

  // TODO: update input signature
  async updateUser(user: any): Promise<INetworkResponse<any>> {
    try {
      const { data } = await axios.patch(
        `${API_URL}/users/${user._id}`,
        {
          ...user,
        },
        {
          headers: {
            Authorization: this.getAuthorization(),
          },
        }
      );

      return { success: true, payload: null };
    } catch (error: any) {
      const errorMessage = error.message || "Unhandled error updating user";
      console.error(error);
      return { success: false, payload: null, message: errorMessage };
    }
  }

  async getTables(showAll: boolean = false): Promise<INetworkResponse<any>> {
    try {
      const url = showAll
        ? `${API_URL}/tables?showDeleted=true`
        : `${API_URL}/tables`;
      const { data } = await axios.get(url, {
        headers: {
          Authorization: this.getAuthorization(),
        },
      });

      return { success: true, payload: data };
    } catch (error: any) {
      const errorMessage = error.message || "Unhandled error getting tables";
      console.error(error);
      return { success: false, payload: null, message: errorMessage };
    }
  }

  async getTableById(tableId: string): Promise<INetworkResponse<ITable>> {
    try {
      const { data } = await axios.get(`${API_URL}/tables/${tableId}`, {
        headers: {
          Authorization: this.getAuthorization(),
        },
      });

      return { success: true, payload: data };
    } catch (error: any) {
      const errorMessage = error.message || "Unhandled error getting tables";
      console.error(error);
      return { success: false, payload: null!, message: errorMessage };
    }
  }

  async createTable(
    tableId: string,
    description: string
  ): Promise<INetworkResponse<any>> {
    try {
      const { data } = await axios.post(
        `${API_URL}/tables`,
        {
          tableId,
          description,
        },
        {
          headers: {
            Authorization: this.getAuthorization(),
          },
        }
      );

      return { success: true, payload: data.insertedId };
    } catch (error: any) {
      const errorMessage = error.message || "Unhandled error adding new table";
      console.error(error);
      return { success: false, payload: null, message: errorMessage };
    }
  }

  async deleteTable(id: string): Promise<INetworkResponse<any>> {
    try {
      const { data } = await axios.delete(`${API_URL}/tables/${id}`, {
        headers: {
          Authorization: this.getAuthorization(),
        },
      });

      return { success: true, payload: null };
    } catch (error: any) {
      const errorMessage = error.message || "Unhandled error deleting table";
      console.error(error);
      return { success: false, payload: null, message: errorMessage };
    }
  }

  async softDeleteTable(id: string): Promise<INetworkResponse<any>> {
    try {
      const { data } = await axios.post(
        `${API_URL}/tables/${id}/softDelete`,
        {},
        {
          headers: {
            Authorization: this.getAuthorization(),
          },
        }
      );

      return { success: true, payload: null };
    } catch (error: any) {
      const errorMessage =
        error.message || "Unhandled error soft-deleting table";
      console.error(error);
      return { success: false, payload: null, message: errorMessage };
    }
  }

  async undoSoftDeleteTable(id: string): Promise<INetworkResponse<any>> {
    try {
      const { data } = await axios.post(
        `${API_URL}/tables/${id}/undosoftDelete`,
        {},
        {
          headers: {
            Authorization: this.getAuthorization(),
          },
        }
      );

      return { success: true, payload: null };
    } catch (error: any) {
      const errorMessage = error.message || "Unhandled error restoring table";
      console.error(error);
      return { success: false, payload: null, message: errorMessage };
    }
  }

  async updateTables(table: any): Promise<INetworkResponse<any>> {
    try {
      const { data } = await axios.patch(
        `${API_URL}/tables/${table._id}`,
        {
          ...table,
        },
        {
          headers: {
            Authorization: this.getAuthorization(),
          },
        }
      );

      return { success: true, payload: null };
    } catch (error: any) {
      const errorMessage = error.message || "Unhandled error updating table";
      console.error(error);
      return { success: false, payload: null, message: errorMessage };
    }
  }

  async getAllPersistentMessages(): Promise<INetworkResponse<any>> {
    try {
      const { data } = await axios.get(`${API_URL}/channel`, {
        headers: {
          Authorization: this.getAuthorization(),
        },
      });

      return {
        success: true,
        payload: data,
      };
    } catch (error: any) {
      const errorMessage =
        error.message || "Unexpected error getting persistent messages";
      console.error(error);
      return { success: false, payload: null, message: errorMessage };
    }
  }

  async getRestaurant(
    restaurantId: string
  ): Promise<INetworkResponse<IRestaurantInfo>> {
    try {
      const { data } = await axios.get(
        `${API_URL}/restaurants/${restaurantId}`,
        {
          headers: {
            Authorization: this.getAuthorization(),
          },
        }
      );

      return {
        success: true,
        payload: data,
      };
    } catch (error: any) {
      const errorMessage =
        error.message || "Unexpected error getting restaurant";
      console.error(error);
      return { success: false, payload: null, message: errorMessage };
    }
  }

  async setMenu(
    name?: string,
    url?: string,
    type?: string,
    seletedFile?: File
  ): Promise<INetworkResponse<any>> {
    try {
      const formData = new FormData();
      if (type === ETypeOfMenu.PDF) {
        //* NEED UPATE API to able to upload file
        if (seletedFile) {
          formData.append("file", seletedFile);
          formData.append("folderName", "dev");
          const { data } = await axios.post(`${API_URL}/upload`, formData, {
            headers: {
              Authorization: this.getAuthorization(),
              "content-type": "multipart/form-data",
            },
          });
          url = data.fileUrl;
        }
      }
      const { data } = await axios.post(
        `${API_URL}/restaurants/menu`,

        {
          name: name,
          url: url,
          type: type,
        },

        {
          headers: {
            Authorization: this.getAuthorization(),
          },
        }
      );
      return {
        success: true,
        payload: data,
      };
    } catch (error: any) {
      const errorMessage = error.message || "Unexpected error saving menu";
      console.error(error);
      return { success: false, payload: null, message: errorMessage };
    }
  }

  async uploadFile(
    folderName: string,
    seletedFile?: File
  ): Promise<INetworkResponse<any>> {
    try {
      const formData = new FormData();

      if (seletedFile) {
        formData.append("file", seletedFile);
      }
      formData.append("folderName", folderName);
      const { data } = await axios.post(`${API_URL}/upload`, formData, {
        headers: {
          Authorization: this.getAuthorization(),
          "content-type": "multipart/form-data",
        },
      });

      return {
        success: true,
        payload: data,
      };
    } catch (error: any) {
      const errorMessage = error.message || "Unexpected error saving menu";
      console.error(error);
      return { success: false, payload: null, message: errorMessage };
    }
  }

  async deleteMenu(menuId: string): Promise<INetworkResponse<any>> {
    try {
      const { data } = await axios.delete(
        `${API_URL}/restaurants/menu/${menuId}`,
        {
          headers: {
            Authorization: this.getAuthorization(),
          },
        }
      );
      return {
        success: true,
        payload: data,
      };
    } catch (error: any) {
      const errorMessage = error.message || "Unexpected error deleting menu";
      console.error(error);
      return { success: false, payload: null, message: errorMessage };
    }
  }

  async updateMenu(
    menuName: string,
    menuURL: string,
    type : string
  ): Promise<INetworkResponse<any>> {
    try {
      const { data } = await axios.patch(
        `${API_URL}/restaurants/menu/${menuName}`,
        {
          name: menuName,
          url: menuURL,
          type: type,
        },
        {
          headers: {
            Authorization: this.getAuthorization(),
          },
        }
      );

      return {
        success: true,
        payload: data,
      };
    } catch (error: any) {
      const errorMessage = error.message || "Unexpected error updating menu";
      console.error(error);
      return { success: false, payload: null, message: errorMessage };
    }
  }

  async clearTable(tableId: string): Promise<INetworkResponse<any>> {
    try {
      const { data } = await axios.post(
        `${API_URL}/tables/${tableId}/clear`,
        {},
        {
          headers: {
            Authorization: this.getAuthorization(),
          },
        }
      );

      return {
        success: true,
        payload: data,
      };
    } catch (error: any) {
      const errorMessage = error.message || "Unexpected error at clear table";
      console.error(error);
      return { success: false, payload: null, message: errorMessage };
    }
  }
  async regenreateQRCode(tableId: string): Promise<INetworkResponse<any>> {
    try {
      const { data } = await axios.post(
        `${API_URL}/tables/${tableId}/regenerate`,
        {},
        {
          headers: {
            Authorization: this.getAuthorization(),
          },
        }
      );

      return {
        success: true,
        payload: data,
      };
    } catch (error: any) {
      const errorMessage =
        error.message || "Unexpected error at regenerate QR Code";
      console.error(error);
      return { success: false, payload: null, message: errorMessage };
    }
  }

  async getRestaurants(
    showAll: boolean = false
  ): Promise<INetworkResponse<any>> {
    try {
      const url = showAll
        ? `${API_URL}/restaurants?showDeleted=true`
        : `${API_URL}/restaurants`;
      const { data } = await axios.get(url, {
        headers: {
          Authorization: this.getAuthorization(),
        },
      });

      return {
        success: true,
        payload: data,
      };
    } catch (error: any) {
      const errorMessage =
        error.message || "Unexpected error getting restaurants";
      console.error(error);
      return { success: false, payload: null, message: errorMessage };
    }
  }

  async newRestaurant(
    name: string,
    owner: string
  ): Promise<INetworkResponse<any>> {
    try {
      const { data } = await axios.post(
        `${API_URL}/restaurants`,
        {
          name,
          owner,
        },
        {
          headers: {
            Authorization: this.getAuthorization(),
          },
        }
      );

      return {
        success: true,
        payload: data,
      };
    } catch (error: any) {
      const errorMessage =
        error.message || "Unexpected error adding new restaurant";
      console.error(error);
      return { success: false, payload: null, message: errorMessage };
    }
  }

  async deleteRestaurant(id: string): Promise<INetworkResponse<any>> {
    try {
      const { data } = await axios.delete(`${API_URL}/restaurants/${id}`, {
        headers: {
          Authorization: this.getAuthorization(),
        },
      });

      return {
        success: true,
        payload: data,
      };
    } catch (error: any) {
      const errorMessage =
        error.message || "Unexpected error deleting restaurant";
      console.error(error);
      return { success: false, payload: null, message: errorMessage };
    }
  }

  async softDeleteRestaurant(id: string): Promise<INetworkResponse<any>> {
    try {
      const { data } = await axios.post(
        `${API_URL}/restaurants/${id}/softdelete`,
        {},
        {
          headers: {
            Authorization: this.getAuthorization(),
          },
        }
      );

      return {
        success: true,
        payload: data,
      };
    } catch (error: any) {
      const errorMessage =
        error.message || "Unexpected error soft-deleting restaurant";
      console.error(error);
      return { success: false, payload: null, message: errorMessage };
    }
  }

  async undoSoftDeleteRestaurant(id: string): Promise<INetworkResponse<any>> {
    try {
      const { data } = await axios.post(
        `${API_URL}/restaurants/${id}/undosoftdelete`,
        {},
        {
          headers: {
            Authorization: this.getAuthorization(),
          },
        }
      );

      return {
        success: true,
        payload: data,
      };
    } catch (error: any) {
      const errorMessage =
        error.message || "Unexpected error restoring restaurant";
      console.error(error);
      return { success: false, payload: null, message: errorMessage };
    }
  }

  async updateRestaurant(
    id: string,
    name: string,
    owner: string
  ): Promise<INetworkResponse<any>> {
    try {
      const { data } = await axios.patch(
        `${API_URL}/restaurants/${id}`,
        {
          name,
          owner,
        },
        {
          headers: {
            Authorization: this.getAuthorization(),
          },
        }
      );

      return {
        success: true,
        payload: data,
      };
    } catch (error: any) {
      const errorMessage =
        error.message || "Unexpected error updating restaurant";
      console.error(error);
      return { success: false, payload: null, message: errorMessage };
    }
  }

  async addNewClient(newClient: any): Promise<INetworkResponse<any>> {
    try {
      const { data } = await axios.post(
        `${API_URL}/users/newclient`,
        {
          ...newClient,
        },
        {
          headers: {
            Authorization: this.getAuthorization(),
          },
        }
      );

      return {
        success: true,
        payload: data,
      };
    } catch (error: any) {
      const errorMessage =
        error.message || "Unexpected error updating restaurant";
      console.error(error);
      return { success: false, payload: null, message: errorMessage };
    }
  }

  async updateOwnersPassword(
    ownerName: string,
    newPassword: string
  ): Promise<INetworkResponse<any>> {
    try {
      const { data } = await axios.post(
        `${API_URL}/users/${ownerName}/changepass`,
        {
          password: newPassword,
        },
        {
          headers: {
            Authorization: this.getAuthorization(),
          },
        }
      );

      return {
        success: true,
        payload: data,
      };
    } catch (error: any) {
      const errorMessage =
        error.message || "Unexpected error updating restaurant";
      console.error(error);
      return { success: false, payload: null, message: errorMessage };
    }
  }

  async deleteBuzz(buzz: string): Promise<INetworkResponse<any>> {
    try {
      const { data } = await axios.delete(
        `${API_URL}/restaurants/delete/buzz`,
        {
          headers: {
            Authorization: this.getAuthorization(),
          },
          data: {
            buzz,
          },
        }
      );

      return { success: true, payload: null };
    } catch (error: any) {
      const errorMessage = error.message || "Unhandled error deleting buzz";
      console.error(error);
      return { success: false, payload: null, message: errorMessage };
    }
  }

  async newBuzz(BuzzItem: Array<BuzzItem>): Promise<INetworkResponse<any>> {
    try {
      const { data } = await axios.post(
        `${API_URL}/restaurants/buzz/`,
        BuzzItem,
        {
          headers: {
            Authorization: this.getAuthorization(),
          },
        }
      );

      return { success: true, payload: null };
    } catch (error: any) {
      const errorMessage = error.message || "Unhandled error deleting buzz";
      console.error(error);
      return { success: false, payload: null, message: errorMessage };
    }
  }

  async getBroadCasterList(): Promise<INetworkResponse<any>> {
    try {
      const { data } = await axios.get(`${API_URL}/restaurants/broadcaster/`, {
        headers: {
          Authorization: this.getAuthorization(),
        },
      });

      return { success: true, payload: data };
    } catch (error: any) {
      const errorMessage = error.message || "Unhandled error deleting buzz";
      console.error(error);
      return { success: false, payload: null, message: errorMessage };
    }
  }

  async getBuzzList(): Promise<INetworkResponse<BuzzItem[] | null>> {
    try {
      const { data } = await axios.get(`${API_URL}/restaurants/buzz/`, {
        headers: {
          Authorization: this.getAuthorization(),
        },
      });

      return { success: true, payload: data };
    } catch (error: any) {
      const errorMessage = error.message || "Unhandled error deleting buzz";
      console.error(error);
      return { success: false, payload: null, message: errorMessage };
    }
  }

  async updateSurveyData(
    surveyData: ISurveyData
  ): Promise<INetworkResponse<string[] | null>> {
    try {
      const { data } = await axios.post(
        `${API_URL}/restaurants/survey/`,
        {
          ...surveyData,
        },
        {
          headers: {
            Authorization: this.getAuthorization(),
          },
        }
      );

      return { success: true, payload: data };
    } catch (error: any) {
      const errorMessage =
        error.message || "Unhandled error updating survey data";
      console.error(error);
      return { success: false, payload: null, message: errorMessage };
    }
  }

  async updateWelcomeMessage(
    newWelcomeMessage: string
  ): Promise<INetworkResponse<string[] | null>> {
    try {
      const { data } = await axios.post(
        `${API_URL}/restaurants/welcomeMessage/`,
        {
          newWelcomeMessage,
        },
        {
          headers: {
            Authorization: this.getAuthorization(),
          },
        }
      );

      return { success: true, payload: data };
    } catch (error: any) {
      const errorMessage =
        error.message || "Unhandled error updating welcome message";
      console.error(error);
      return { success: false, payload: null, message: errorMessage };
    }
  }

  static async sendMessageToBackend(
    tableId: string | undefined,
    message: IMessage,
    channel: string,
    event: string
  ): Promise<INetworkResponse<any>> {
    if (window._env_.PERSIST_MESSAGE_LOCAL !== "true") {
      return Promise.resolve(null as any);
    }

    const finalMessage = {
      tableId,
      time_ms: message.timestamp,
      events: [
        {
          channel: channel,
          data: JSON.stringify(message),
          event: event,
          name: "client_event",
          socket_id: "13194.52156738",
        },
      ],
    };

    try {
      const { data } = await axios.post(`${API_URL}/channel/message`, {
        ...finalMessage,
      });

      return {
        success: true,
        payload: data,
      };
    } catch (error: any) {
      const errorMessage =
        error.message || "Unexpected error updating restaurant";
      console.error(error);
      return { success: false, payload: null, message: errorMessage };
    }
  }

  pusherAuthorizerStaff(channel: PusherTypes.Channel, authToken?: string) {
    return {
      authorize: (socketId: string, callback: any) => {
        fetch(`${window._env_.API_URL}/pusher/staffAuth`, {
          method: "POST",
          headers: new Headers({
            "Content-Type": "application/json",
            Authorization: this.getAuthorization(),
          }),
          body: JSON.stringify({
            socketId: socketId,
            channelId: channel.name,
          }),
        })
          .then((res: any) => {
            if (!res.ok) {
              throw new Error(`Received ${res.statusCode} from `);
            }

            return res.json();
          })
          .then((data) => {
            callback(null, data);
          })
          .catch((err) => {
            console.error("ERR", err);

            callback(new Error(`Error calling auth endpoint: ${err}`), {
              auth: "",
            });
          });
      },
    };
  }

  pusherAuthorizerRestaurant(
    socketId: string,
    channelId: string,
    authToken: string,
    callback: any
  ) {
    fetch(`${window._env_.API_URL}/pusher/restaurantAuth`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: "Bearer " + authToken,
      }),
      body: JSON.stringify({
        socketId: socketId,
        channelId: channelId,
      }),
    })
      .then((res: any) => {
        if (!res.ok) {
          throw new Error(`Received ${res.statusCode} from `);
        }

        return res.json();
      })
      .then((data) => {
        callback(null, data);
      })
      .catch((err) => {
        console.error("ERR", err);

        callback(new Error(`Error calling auth endpoint: ${err}`), {
          auth: "",
        });
      });
  }

  // Static functions
  static async getPersistentMessages(
    channelId: string
  ): Promise<INetworkResponse<any>> {
    try {
      const { data } = await axios.get(`${API_URL}/channel/${channelId}`);

      return {
        success: true,
        payload: data || [],
      };
    } catch (error: any) {
      const errorMessage =
        error.message || "Unhandled error getting persistent messages";
      console.error(error);
      return { success: false, payload: null, message: errorMessage };
    }
  }

  static pusherAuthorizer(
    channel: PusherTypes.Channel,
    restaurantId: string,
    tableId: string
  ) {
    return {
      authorize: (socketId: string, callback: any) => {
        fetch(`${window._env_.API_URL}/pusher/auth`, {
          method: "POST",
          headers: new Headers({ "Content-Type": "application/json" }),
          body: JSON.stringify({
            socketId: socketId,
            channelId: channel.name,
            restaurantId: restaurantId,
            tableId: tableId,
          }),
        })
          .then((res: any) => {
            if (!res.ok) {
              throw new Error(`Received ${res.statusCode} from `);
            }

            return res.json();
          })
          .then((data) => {
            callback(null, data);
          })
          .catch((err) => {
            console.error("ERR", err);

            callback(new Error(`Error calling auth endpoint: ${err}`), {
              auth: "",
            });
          });
      },
    };
  }

  static async clientHandshake(
    tableId: string | undefined,
    dynamicCode: string | undefined,
    restaurantId: string | undefined
  ): Promise<any> {
    try {
      const { data } = await axios.post(
        `${API_URL}/tables/${tableId}/getChannelInfo`,
        {
          dynamicCode,
          restaurantId,
        }
      );

      return { success: true, payload: data };
    } catch (error: any) {
      const errorMessage =
        error.message || "Unhandled error regenerating QR code";
      console.error(error);
      return { success: false, message: errorMessage };
    }
  }
}
