import { lazy, Suspense } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import './App.css';
import { AuthProvider, RequireAuth } from './Auth/Auth';
import { SuperadminAuthProvider, SuperadminRequireAuth } from './Auth/SuperadminAuth';

// Views
const AdminHome = lazy(() => import('./User/Home').then(({ AdminHome }) => ({ default: AdminHome })));
const UserLogin = lazy(() => import('./User/Login').then(({ UserLogin }) => ({ default: UserLogin })));
const Users = lazy(() => import('./User/Users').then(({ Users }) => ({ default: Users })));
const Tables = lazy(() => import('./User/Tables').then(({ Tables }) => ({ default: Tables })));
const Menu = lazy(() => import('./User/Menu').then(({ Menu }) => ({ default: Menu })));
const Buzz = lazy(() => import('./User/Buzz').then(({ Buzz }) => ({ default: Buzz })));
const TermsOfUse = lazy(() => import('./TermsOfUse/BaseScreen').then(({ BaseScreen }) => ({ default: BaseScreen })));

// SuperAdmin views
const SuperadminLogin = lazy(() => import('./Superadmin/Login/Login').then(({ SuperadminLogin }) => ({ default: SuperadminLogin })));
const SuperAdminHome = lazy(() => import('./Superadmin/Home/SuperAdminHome').then(({ SuperAdminHome }) => ({ default: SuperAdminHome })));
const Restaurants = lazy(() => import('./Superadmin/Restaurants/Restaurants').then(({ Restaurants }) => ({ default: Restaurants })));

// Customer view
const CustomerView = lazy(() => import('./CustomerViews/CustomerView').then(({ CustomerView }) => ({ default: CustomerView })));
const NotFound = lazy(() => import('./CustomerViews/Components/NotFound/NotFound').then(({ NotFound }) => ({ default: NotFound })));
const DrawerContainer = lazy(() => import('./CustomerViews/Components/SideMenu/SideMenu').then(({ DrawerContainer }) => ({ default: DrawerContainer })));
const ChangeLanguage = lazy(() => import('./CustomerViews/Components/ChangeLanguage/ChangeLanguage').then(({ ChangeLanguage }) => ({ default: ChangeLanguage })));
const TableViewForm = lazy(() => import('./RestaurantViews/TableViewForm').then(({ TableViewForm }) => ({ default: TableViewForm })));

// Restaurant view
const StationView = lazy(() => import('./RestaurantTableView/StationView').then(({ StationView }) => ({ default: StationView })));


function App() {
  return (
    <div className="App">
      <AuthProvider>
        <BrowserRouter>
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              {/* Customer view */}
              <Route path="/sideMenu" element={<DrawerContainer />} />
              <Route path="/changeLanguage" element={<ChangeLanguage />} />

              {/* Public routes */}
              <Route path="/" element={<UserLogin />} />
              <Route path="/login" element={<UserLogin />} />
              <Route path="/chat/:restaurantId/:tableId/:dynamicCode" element={<CustomerView />} />
              <Route path="/termsOfUse" element={<TermsOfUse />} />
              <Route path="/notFound" element={<NotFound />} />

              {/* Auth routes */}
              <Route element={<RequireAuth />}>
                <Route path="/home" element={<AdminHome />} />
                <Route path="/users" element={<Users />} />
                <Route path="/tables" element={<Tables />} />
                <Route path="/menu" element={<Menu />} />
                <Route path="/buzz" element={<Buzz />} />

                <Route path="/restaurant/stationView" element={<StationView />} />
                <Route path="/restaurant/table/:tableId" element={<TableViewForm />} />
                <Route path="/restaurant/termsOfUse" element={<TermsOfUse />} />
              </Route>
            </Routes>
          </Suspense>
        </BrowserRouter>
      </AuthProvider>

      {/* Superadmin views */}
      <SuperadminAuthProvider>
        <BrowserRouter>
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route path="/superadmin" element={<SuperadminLogin />} />
              <Route path="/superadmin/login" element={<SuperadminLogin />} />

              <Route element={<SuperadminRequireAuth />}>
                <Route path="/superadmin/home" element={<SuperAdminHome />} />
                <Route path="/superadmin/restaurants" element={<Restaurants />} />
              </Route>

            </Routes>
          </Suspense>
        </BrowserRouter>
      </SuperadminAuthProvider>
    </div>
  );
}

export default App;
